<template>
  <div class="w-full">
    <span
      v-if="props.label"
      class="mr-2 mb-2 flex items-center md:whitespace-nowrap"
    >
      {{ props.label }}
      <FontAwesomeIcon
        ref="tooltipElement"
        class="text-xl mx-1 block text-blue-500"
        :icon="['fas', 'circle-info']"
      />
    </span>
    <AppSelect
      v-model="auth.activeFunderId"
      name="chooseFunder"
      :options="options"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  useTooltip,
  AppSelect,
} from '@mogelijk-technologies/ui-library';
import { useAuthStore } from '@stores/useAuthStore';

export interface Props {
  label?: string;
  newEntityButton?: boolean;
}

const tooltipElement = ref(null);

useTooltip({
  ref: tooltipElement,
  content:
    'Selecteer hier vanuit welke rechtsvorm u een object wilt claimen of opteren.',
});

const auth = useAuthStore();

const props = withDefaults(defineProps<Props>(), {
  label: '',
  newEntityButton: false,
});

const privateGroup = {
  value: 0,
  name: 'Privé',
  icon: 'user',
};

const funderGroups = computed(() => {
  return auth.user?.funders?.length ? {
    type: 'group',
    label: 'Mijn bedrijf',
    icon: 'buildings',
    options: [
      ...auth.user.funders.map(funder => ({
        type: 'option',
        value: funder.id as number,
        name: funder.name,
      })),
    ],
  } : {
    options: [],
  };
});

const options = computed(() => [
  privateGroup,
  ...funderGroups.value.options,
].filter(item => item));

</script>
