<script setup lang="ts">
import { useInvestorTestStore } from '@stores/useInvestorTestStore';
import { useRouter } from 'vue-router';

const {
  isProfessionalismTestInExperienced,
  isProfessionalismTestPendingRequest,
} = toRefs(useInvestorTestStore());

const router = useRouter();

const toRequest = () => {
  router.push('onboarding/aanvraag');
};

</script>

<template>
  <p
    class="flex w-full flex-wrap items-center justify-center bg-orange-100 p-4 text-orange-900 text-body text-center"
  >
    <FontAwesomeIcon
      :icon="['fas', 'circle-exclamation']"
      size="1x"
      class="icon mr-2 inline-block"
    />
    <span class="text-body-strong">Let op!</span>&nbsp;
    <span v-if="isProfessionalismTestPendingRequest">
      Uw verzoek is in behandeling genomen. U kunt het aanbod bekijken maar nog niet claimen of opties nemen.
    </span>
    <span v-else-if="isProfessionalismTestInExperienced">
      Het verzoek om u te kwalificeren als ervaren belegger is nog niet in behandeling genomen.
      <span
        data-test-id="professionalism-banner-to-request"
        class="underline cursor-pointer"
        @click="toRequest"
      >
        Klik hier om uw verzoek in te dienen.
      </span>
    </span>
    <span v-else>
      U wordt op dit moment nog niet gekwalificeerd als ervaren investeerder. Neem contact met ons op om de verdere mogelijkheden te bespreken.
    </span>
  </p>
</template>

<style scoped lang="css">
:deep(.icon .fa-primary) {
  fill: white !important;
  opacity: 1 !important;
}
:deep(.icon .fa-secondary) {
  fill: var(--color-blue-300);
}
</style>
